import React, { useState, useRef, useEffect } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
// import styled from 'styled-components';
// import tw from 'twin.macro';

const Image = (props) => {
  const image = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data } = props;

  useEffect(() => {
    const img = image.current;
    if (img && img.complete) {
      setIsLoaded(true);
    }
  });

  if (!data) return null;

  // const height = props.data.mediaDetails?.height || false;
  // const width = props.data.mediaDetails?.width || false;
  // let aspectRatio = false;
  // let orientation = false;

  // if (height && width) {
  //   // orientation = width > height ? 'landscape' : 'portrait';
  //   // aspectRatio = orientation === 'portrait' ? (width / height) * 100 : (height / width) * 100;
  //   // aspectRatio = (width / height) * 100;
  // }

  // const Aspect = styled.div`
  //   position: relative;
  //   display: block;
  //   &:before {
  //     content: '';
  //     display: block;
  //     width: 100%;
  //     padding-bottom: ${aspectRatio + '%'};
  //   }
  //   > img {
  //     ${tw`absolute inset-0 w-full h-full`}
  //   }
  // `;

  const { publicURL } = data.localFile || {};
  const { fluid } = (data.localFile || {}).childImageSharp || {};
  return (
    <>
      {fluid && <Img fluid={fluid} alt={data.altText} {...props} />}
      {!fluid && <img ref={image} src={publicURL} alt={data.altText} {...props} onLoad={() => setIsLoaded(true)} style={{ opacity: !isLoaded ? 0 : 1, ...props.style }} className={`transition-opacity duration-700 ${props.className || ' '}`} />}

      {/* {aspectRatio && (
        <Aspect {...props}>
          <img ref={image} src={publicURL} alt={data.altText} onLoad={() => setIsLoaded(true)} style={{ opacity: !isLoaded ? 0 : 1 }} className={`transition-opacity duration-700 ${props.imageClassName || ' '} `} />
        </Aspect>
      )} */}
    </>
  );
};

export default Image;
